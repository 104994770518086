@import '../../variables.scss';

.Header {
  background-color: #fff;
  width: calc(100% - 20px - 20px);
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .brand {
    .link {
      text-decoration: none;
    }

    .logo {
      color: $primary-color;
    }

    .name {
      margin-left: 5px;
      font-weight: bold;
      font-size: 22px;
      color: $primary-color;
    }
  }

  .menu {
    display: flex;
    align-items: center;

    .account {

      .btn-toggle-account-menu {
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        padding: 7px 10px;
        border-radius: 20px;
        background-color: $component-background-color;
        color: $component-text-color;
        cursor: pointer;
        text-decoration: none;
        //box-shadow: $component-box-shadow;

        span {
          margin-left: 5px;
          font-size: 16px;
        }
      }
    }

    .dropdown-menu {
      position: relative;
      display: inline-block;
    
      .sub-menu {
        display: none;
        min-width: 160px;
        position: absolute; 
        right: 0;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: $component-box-shadow;
        z-index: 1;
    
        .btn-toggle-account-menu {
          color: #000;
          padding: 10px 16px; 
          text-decoration: none;
          display: flex; 
          align-items: center; 
    
          &:hover {
            background-color: #f1f1f1; 
          }
    
          span {
            margin-left: 10px; 
          }
        }
      }
    
      &:hover .sub-menu {
        display: block;
      }
    }
    

    .btn-settings {
      outline: none;
      border: none;
      width: 45px;
      height: 45px;
      margin-left: 10px;
      border-radius: 50%;
      background-color: $component-background-color;
      color: $component-text-color;
      cursor: pointer;
      //box-shadow: $component-box-shadow;
      transition: transform 250ms;

      &:hover {
        transform: rotate(30deg);
      }
    }
  }

  .balance {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: $component-background-color;
    box-shadow: $component-box-shadow;
    color: $component-text-color;

    .label {
      font-size: 12px;
      font-weight: bold;
    }

    .value {
      margin-top: 3px;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 374px) {
  .Header {
    flex-direction: column;

    .menu {
      margin-top: 20px;
    }
  }
}