@import '../../variables.scss';

.club-page-layout {
    background: linear-gradient(0deg, #441992 0%, #6753E7 48.82%, #5AB4FF 100%);
    height: 100%;

    .main-content {
        max-width: 800px;
        margin: 0 auto;
        padding: 80px 20px;
        text-align: center;

        .main-logo {
            border-radius: 50%;
            margin: 0 auto;
            width: 100px;
            height: 100px;
        }

        .main-title {
            color: #fff;
        }

        .links-section {
            margin-top: 50px;
        }

        .link-item {
            margin-bottom: 20px;
        }

        .link-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        transition: all 0.15s ease-in-out;
        padding: 8px 10px;
        min-height: 70px;
        background: #fff;
        color: #1d3548;
        overflow: hidden;
        position: relative;
        text-decoration: none;
        }
        .link-thumbnail{
            background-image: url("https://cdn-b.heylink.me/media/links/thumbnails/34d4ba92d0a8416697b4ee382203360f.webp");
            width: 54px;
            min-width: 54px;
            height: 54px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            transition: all 0.15s ease-in-out;
        }
        .link-info{
            flex: 3 0 auto;
            width: calc(100% - 54px - 20px* 2 - 20px);
            padding: 5px 8px;
            text-align: left;
            color: #343498;
            text-decoration: none;
            font-size: 20px;
            font-weight: 600;
        }
    }
}