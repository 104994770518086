@import '../../variables.scss';
.page-title{
  color: #f95a5a;
  text-align: center;
  margin-top: 100px;
  padding: 0 20px;
}

.GameList {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .game {
      display: flex;
      min-width: 230px;
      flex-direction: column;
      align-items: center;
      margin: 0 50px;
      padding: 30px;
      border-radius: 10px;
      background-color: $component-background-color;
      box-shadow: $component-box-shadow;

      .logo {
        height: 100px;
      }

      span {
        margin-top: 15px;
        font-weight: bold;
      }

      .btn-play {
        text-decoration: none;
        width: 150px;
        margin-top: 10px;
        padding: 10px 0;
        border-radius: 4px;
        background-color: $button-background-color;
        text-align: center;
        font-weight: bold;
        color: $button-text-color;
        box-shadow: $button-box-shadow;
        cursor: pointer;
      }
    }
  }
}
