@import '../../variables.scss';

.Game {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

  canvas {
    display: block;
    margin: 0 auto;
  }
}
