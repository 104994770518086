@import '../../variables.scss';

.page-layout {
    background-color: $background-color;

    .main-content{
        max-width: 800px;
        margin: 0 auto;
        padding: 50px 20px;
    }
}